import { Paper, Grid, Text, Button, Table, Group, Tabs, Select } from '@mantine/core';
import React, { useCallback, useEffect, useState } from 'react';
import { getSubscriptionMetrics } from '../utils/util';
import { useMedplum } from '@medplum/react';

interface PaymentStatusData {
    customer: string;
    email: string;
    subscriptionDateTime: string;
    subscriptionStatus: string;
    //subscriptionPlan: string;
    amount: number;
    id: string;
    invoiceId: string;
}

const paymentStatusTableFields = ['name', 'email', 'dateTime', 'status',  'amount', 'subscription', 'paymentId',];

const statusDisplayMap: { [key: string]: string } = {
    trialing: 'Trialing',
    active: 'Active',
    past_due: 'Past Due',
    canceled: 'Canceled',
    unpaid: 'Unpaid',
};


const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const renderValue = (data: PaymentStatusData, field: string) => {
    switch (field) {
        case 'name':
            return data?.customer || '-';
        case 'email':
            return data?.email || '-';
        case 'dateTime':
            return data?.subscriptionDateTime ? new Date(data.subscriptionDateTime).toLocaleDateString() : '-';
        case 'status':
            return data?.subscriptionStatus ? statusDisplayMap[data.subscriptionStatus] || capitalizeFirstLetter(data.subscriptionStatus) : '-';
        case 'subscription':
            return data?.id || '-';
        case 'amount':
            return data?.amount ? `$${data.amount}` : '-';
        case 'paymentId':
            return data?.invoiceId || '-';
        // case 'gateWay':
        //     return data?.id || '-';
        default:
            return '-';
    }
};

const TableHeaders = () => (
    <thead>
        <tr>
            {paymentStatusTableFields.map((field, index) => (
                <th key={index} style={field === 'name' ? { width: '150px' } : field === 'dateTime' ? { width: '170px' } : (field === 'status') ? { width: '100px' } : {}}>
                    <Group position="apart" noWrap>
                        <Text weight={500} size="sm">
                            {field === 'name' ? 'Name' :
                                field === 'email' ? 'Email' :
                                    field === 'dateTime' ? 'Subscription Date' :
                                        field === 'status' ? 'Status' :
                                            field === 'subscription' ? 'Subscription Id' :
                                                field === 'amount' ? 'Amount' :
                                                    field === 'paymentId' ? 'Invoice Id' :
                                                        // field === 'gateWay' ? 'Gateway'
                                                        ''}
                        </Text>
                    </Group>
                </th>
            ))}
            <th></th>
        </tr>
    </thead>
);

const TableRows = ({ data }: { data: PaymentStatusData[] }) => (
    <tbody className='payment-status-table-body'>
        {data.length > 0 ? data.map((resource, index) => (
            <tr key={index} style={{ border: '1px solid #ebeae9' }}>
                {paymentStatusTableFields.map((field, i) => (
                    <td key={`cell-${index}-${i}`}
                        style={(field === 'amount' || field == 'dateTime') ? { textAlign: 'center' } : {}}
                    >
                        {renderValue(resource, field)}</td>
                ))}
            </tr>
        )) : <tr><td colSpan={paymentStatusTableFields.length + 1}>No data available</td></tr>}
    </tbody>
);

function DashBoard() {
    const medplum = useMedplum();
    const [paymentStatusData, setPaymentStatusData] = useState<PaymentStatusData[]>([]);
    const [duration, setDuration] = useState<string>('30');
    const [paymentStatus, setPaymentStatus] = useState<string>('active');

    const fetchPaymentStatusData = useCallback(async () => {
        try {
            const data = await getSubscriptionMetrics(medplum, duration, paymentStatus, '4');
            setPaymentStatusData(data.subscriptions || []);
        } catch (error) {
            console.error('Error fetching payment status data:', error);
            setPaymentStatusData([]);
        }
    }, [medplum, duration, paymentStatus]);

    useEffect(() => {
        fetchPaymentStatusData();
    }, [duration, paymentStatus]);

    const handleTabChange = (value: string | null) => {
        if (value !== null) {
            setDuration(value);
        }
    };

    const handlePaymentStatusChange = (value: string | null) => {
        if (value !== null) {
            setPaymentStatus(value);
        }
    }

    return (
        <Paper m="lg" p="xs" sx={{ border: '1px solid #EAECF0' }}>
            <Grid sx={{ justifyContent: 'space-between', margin: '8px' }}>
                <Grid.Col span={6}>
                    <Text align="left" size={18} fw={600} ml={10} pb={10}>Payment Status</Text>
                </Grid.Col>
                <Grid.Col span={6} sx={{ textAlign: 'end' }}>
                    <Button className='download-btn'>
                        <img src="../../../../img/dashboard/download.svg" alt="Download" />
                        <Text pl={5} size={14} color='#344054' fw={600}>Download Report</Text>
                    </Button>
                </Grid.Col>
            </Grid>
            <Grid sx={{ justifyContent: 'space-between', borderTop: '1px solid #D0D5DD' }}>
                <Grid.Col span={4} sx={{ textAlign: 'end' }}>
                </Grid.Col>
                <Grid.Col span={8} className='filter-div'>
                    <Grid align="center" ml={20}>
                        <Grid.Col span={1}>
                            <Text size={14} fw={500} color='#344054'>Status</Text>
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <Select
                                value={paymentStatus}
                                onChange={handlePaymentStatusChange}
                                data={[
                                    { value: 'trialing', label: 'Trialing' },
                                    { value: 'active', label: 'Active' },
                                    { value: 'past_due', label: 'Past Due' },
                                    { value: 'canceled', label: 'Canceled' },
                                    { value: 'unpaid', label: 'Unpaid' },
                                ]}
                                placeholder="Select status"
                                sx={{ width: 'auto' }}
                            />
                        </Grid.Col>
                        <Grid.Col span={8} pr={22}>
                            <Tabs value={duration} onTabChange={handleTabChange} className='filter-duration'>
                                <Tabs.List className="tabs-list">
                                    <Tabs.Tab value="1" mb={0} className="tab">24 hours</Tabs.Tab>
                                    <Tabs.Tab value="7" mb={0} className="tab">7 days</Tabs.Tab>
                                    <Tabs.Tab value="30" mb={0} className="tab">30 days</Tabs.Tab>
                                    <Tabs.Tab value="180" mb={0} className="tab">6 months</Tabs.Tab>
                                </Tabs.List>
                            </Tabs>
                        </Grid.Col>
                    </Grid>

                </Grid.Col>
            </Grid>
            <Grid>
                <Grid.Col lg={12} sx={{ overflow: 'auto' }}>
                    <Table className='feedback_table'>
                        <TableHeaders />
                        <TableRows data={paymentStatusData} />
                    </Table>
                </Grid.Col>
            </Grid>
        </Paper>
    );
}

export default DashBoard;