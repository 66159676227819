/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { forwardRef, useState, useEffect } from 'react';
import { Button, Text, Center, Group, Modal, MultiSelect, Title, Grid, Autocomplete, Loader, Tooltip } from '@mantine/core';
import { Form, useMedplum } from '@medplum/react';
import { DateInput } from '@mantine/dates';
import { format } from 'date-fns';
import icdCodes from './../seeds/icd-codes.json';
import { ICDCodeProps } from '../constants';
import { fetchPatientDetails, getOtherICDCode, getPatientDetails } from '../utils/util';
import ConfirmBox from '../patients/ConfirmBox';
import { useAppContext } from '../AppProvider';
import { toast } from 'react-toastify';

interface PatientStepperProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (data: any) => void;
  patientData?: any;
  checkLoader: boolean;
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  description: string;
}

const PatientStepper: React.FC<PatientStepperProps> = ({
  isOpen,
  onClose,
  onConfirm,
  patientData,
  checkLoader
}) => {

  const [showConfirmBox, setShowConfirmBox] = useState(false);

  const handleConfirmCancel = () => {
    setShowConfirmBox(false);
    onClose();
  };

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, description, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text>{label}</Text>
            <Text opacity={0.65}>
              {description}
            </Text>
          </div>
        </Group>
      </div>
    )
  );

  const medplum = useMedplum();
  const [currentStep, setCurrentStep] = useState<number>(0);

  const [formData, setFormData] = useState<any>({
    patientDetails: {
      patientName: '',
      birthDate: '',
      mentalHealthDiagnoses: [],
      otherDiagnoses: [],
    },
    patientDemographics: {
      address: {},
    },
  });

  const [validationErrors, setValidationErrors] = useState<any>({});
  const [selectedRace, setSelectedRace] = useState<any>({
    label: '',
    value: ''
  });
  const [diagnosesList, setDiagnosesList] = useState<any[]>([]);
  const [tempDiagnosesList, setTempDignosesList] = useState<any[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const [otherDiagnosesList, setOtherDiagnosesList] = useState<any[]>([]);
  const [tempOtherDiagnosesList, setTempOtherDiagnosesList] = useState<any[]>([]);
  const [selectedOtherOptions, setSelectedOtherOptions] = useState<any[]>([]);
  const [otherSearchQuery, setOtherSearchQuery] = useState<string>('');
  const [patients, setPatients] = useState<any[]>([]);
  const [isPatientNew, setIsPatientNew] = useState<string>('new-patient');
  const [dataLoaded, setDataLoaded] = useState(true);
  const [notfound, setNotfound] = useState(false);
  const [loaderICD, setLoaderICD] = useState(false);
  const [disablebutton, setDisbaleButton] = useState(false);
  const [notFoundMental, setNotFoundMental] = useState(false);
  const { setOldPatientId, zoomPatientName } = useAppContext();
  

  useEffect(() => {
    if (patientData) {
      setPatients([
        {
          label: patientData?.name?.[0].given?.[0],
          value: patientData?.name?.[0].given?.[0],
          id: patientData?.id
        }
      ]);
      extractAndSetPatientDetails(patientData);
      setDataLoaded(false);
      setTimeout(() => {
        setIsPatientNew('new-patient');
      }, 100);
    }
  }, [patientData]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        const button = document.getElementById('save-next-button') as HTMLButtonElement;
        if (button) {
          button.click();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleSearchChange = (query: string): any => {
    setSearchQuery(query);
    setNotFoundMental(false);
    if (query.trim() !== '' && query?.length >= 3) {
      const filteredDiagnosesList = icdCodes.filter(
        (diagnoses) =>
          diagnoses.code?.toLowerCase()?.includes(query?.toLowerCase()) ||
          diagnoses.label?.toLowerCase()?.includes(query?.toLowerCase())
      );

      const updatedListWithValues = filteredDiagnosesList.map((item: ICDCodeProps) => {
        return {
          ...item,
          value: `${item.code} - ${item.label}`,
          label: `${item.code} - ${item.label}`
        };

      });
      if (filteredDiagnosesList.length === 0) {
        setNotFoundMental(true);
      }
      if (selectedOptions?.length) {
        setDiagnosesList([...new Set([...selectedOptions, ...updatedListWithValues])]);
        setTempDignosesList([...new Set([...selectedOptions, ...updatedListWithValues])]);
      } else {
        setDiagnosesList([...updatedListWithValues]);
        setTempDignosesList([...updatedListWithValues]);
      }
    }
  };

  const handleSelectChange = (values: any[]): any => {
    const selectedItems: any = [];
    tempDiagnosesList.forEach((item) => {
      values.forEach((value: any) => {
        if (item.value === value && !selectedItems.some((selectedItem: any) => selectedItem.value === item.value)) {
          const shortLabel = item.label.split(' - ')[1] || item.label;
          selectedItems.push({
            ...item,
            label: shortLabel,
          });
        }
      });
    });
    setSelectedOptions([...selectedItems]);
  };

  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

  // Handle search query change
  const handleOtherSearchChange = (query: string): void => {
    setOtherSearchQuery(query);

    // Clear previous typing timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new typing timeout
    const newTypingTimeout = setTimeout(() => {
      getOtherICDCodeList(query);
    }, 1000); // Adjust the duration as needed

    // Store the new timeout
    setTypingTimeout(newTypingTimeout);
  };

  // Function to make the API call
  const getOtherICDCodeList = (query: string) => {
    setNotfound(false);
    if (query?.length >= 3) {
      setLoaderICD(true);
      getOtherICDCode(query, medplum)
        .then((response) => {
          if (response.length === 0) {
            setNotfound(true);
          }
          setLoaderICD(false);
          const updatedListWithValues = response.map((item: ICDCodeProps) => {
            return {
              ...item,
              value: `${item.code} - ${item.label}`,
              label: `${item.code} - ${item.label}`
            };
          });

          if (selectedOtherOptions?.length) {
            setOtherDiagnosesList([...new Set([...selectedOtherOptions, ...updatedListWithValues])]);
            setTempOtherDiagnosesList([...new Set([...selectedOtherOptions, ...updatedListWithValues])]);
          } else {
            setOtherDiagnosesList([...updatedListWithValues]);
            setTempOtherDiagnosesList([...updatedListWithValues]);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error('Error to fetch other diagnoses');
        });
    }
  };

  // Cleanup function to clear timeout when component unmounts
  useEffect(() => {
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  const handleOtherSelectChange = (values: any[]): any => {
    const selectedItems: any = [];
    tempOtherDiagnosesList.forEach((item) => {
      values.forEach((value: any) => {
        if (item.value === value && !selectedItems.some((selectedItem: any) => selectedItem.value === item.value)) {
          const shortLabel = item.label.split(' - ')[1] || item.label;
          selectedItems.push({
            ...item,
            label: shortLabel,
          });
        }
      });
    });
    setSelectedOtherOptions([...selectedItems]);
  };

  const handleSubmit = (formData: any): any => {
    formData.patientDetails['mentalHealthDiagnoses'] = selectedOptions;
    formData.patientDetails['otherDiagnoses'] = selectedOtherOptions;
    if (formData.patientDemographics?.race) {
      formData.patientDemographics.race = selectedRace;
    }
    setDisbaleButton(true);
    onConfirm(formData);
  };



  const ItemComponent = forwardRef<HTMLDivElement, any>(({ label, code, ...others }: any, ref) => {
    const labelParts = label.split(' - ');
    const displayLabel = labelParts.length > 1 ? labelParts[1] : label;
    return (
      <div ref={ref} {...others}>
        <Grid>
          <Grid.Col md={2} lg={2}>
            {code}
          </Grid.Col>
          <Grid.Col md={10} lg={10}>
            {displayLabel}
          </Grid.Col>
        </Grid>
      </div>
    );
  });

  const validateFormSection = (formDataSection: any) => {
    const errors: any = {};

    if(zoomPatientName){
      return errors;
    }
    if (currentStep === 0) {
      if (!formDataSection?.patientName || formDataSection?.patientName.trim() === '') {
        errors['patientName'] = 'Patient name is required';
      } else if (formDataSection.patientName?.length < 2) {
        errors['patientName'] = 'Patient name should have a minimum of 2 characters.';
      }
    }

    return errors;
  };

  const handleStepSubmit = (formSectionData: any): any => {
    const currentSection = Object.keys(formData)[currentStep];
   
    const validationErrors = validateFormSection(formSectionData[currentSection]);
    setValidationErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      const updatedFormData = {
        ...formData,
        [currentSection]: formSectionData[currentSection],
      };

      setFormData(updatedFormData);
      if (selectedOptions?.length === 0 && updatedFormData.patientDetails?.mentalHealthDiagnoses?.length >= 0) {
        handleSelectChange(updatedFormData.patientDetails.mentalHealthDiagnoses);
      }
      if (selectedOtherOptions?.length === 0 && updatedFormData.patientDetails?.otherDiagnoses?.length >= 0) {
        handleOtherSelectChange(updatedFormData.patientDetails.otherDiagnoses);
      }

      if (formData.patientDetails?.patientName || zoomPatientName) {
        const nameToFormat = formData.patientDetails?.patientName || zoomPatientName;
        formData.patientDetails.patientName = nameToFormat
          .split(' ')
          .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      }

      if (currentStep === steps.length - 1) {
        handleSubmit(updatedFormData);
      } else {
        setCurrentStep(currentStep + 1);
      }
    } else {
      const firstErrorKey = Object.keys(validationErrors)[0];
      const element = document.getElementsByName(firstErrorKey)[0];
      element?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleDateChange = (value: Date | null) => {
    if (value) {
      let normalizedDate = value.toLocaleDateString();
      normalizedDate = format(normalizedDate, 'yyyy-MM-dd');

      setFormData((prevData: any) => ({
        ...prevData,
        patientDetails: {
          ...prevData.patientDetails,
          birthDate: normalizedDate,
        },
      }));
    }
  };

  const handleSkip = (): void => {
    onConfirm(formData);
    onClose();
  };
  const handleOnChange = (value: any) => {
    setDataLoaded(false);
    setFormData((prevData: any) => ({
      ...prevData,
      patientDetails: {
        patientName: value,
      }
    }));
    const filteredPatient = patients?.filter((patient) => patient?.value === value);
    setOldPatientId(filteredPatient?.[0]?.id);
    if (filteredPatient?.length > 0) {
      getPatientDetails(medplum, value)
        .then((response) => {
          extractAndSetPatientDetails(response);
        }).catch((error) => {
          console.log(error);
          setDataLoaded(true);
          setFormData({
            patientDetails: {},
            patientDemographics: {
              address: {},
            },
          });
        });
    } 
    else {
      setDataLoaded(true);
    }
  }
  
  const handleKeyDown = (event: any) => {
    if (event.target.value?.length >= 2) {

      const query = `{ Patients1: PatientList(name: "${event.target.value}", _count: 5) { resourceType id active name { given family } birthDate extension { url valueString }}}`

      fetchPatientDetails(medplum, query)
        .then(async (response) => {
          if (response?.data?.Patients1?.length === 0) { 
            setValidationErrors((prevErrors: any) => ({ ...prevErrors, patientName: undefined }));
            setFormData((prevData: any) => ({
              ...prevData,
              patientDetails: {
                ...prevData.patientDetails,
                patientName: event.target.value,
              },
            }));
          } else {
            let formattedPatients;
            const patients = response?.data?.Patients1?.filter((patient: any) => patient?.active === true);
            if (patients?.length > 0) {
              formattedPatients = patients?.map((patient: any) => formatPatient(patient));
              setPatients(formattedPatients);
            }
          }
        }).catch(() => {
          toast.error('Error to fetch patient details');
        });
    }
    if (event.target.value?.length === 0) {
      setDataLoaded(false);
      setFormData({
        patientDetails: {
          patientName: zoomPatientName || '',
          birthDate: '',
          mentalHealthDiagnoses: [],
          otherDiagnoses: [],
        },
      });
      setTimeout(() => {
        setDataLoaded(true);
      }, 100);
    }
    if (event.target.value) {
      setFormData((prevData: any) => ({
        ...prevData,
        patientDetails: {
          ...prevData.patientDetails,
          patientName: event.target.value,
        },
      }));
    }
  };

  function formatPatient(patient: any) {
    const patientName = patient.name[0].given[0] || zoomPatientName;
    const birthDate = patient.birthDate ? parseDate(patient.birthDate) : '';
    const diagnosisArray = JSON.parse(patient.extension.find((ext: any) => ext.url === "/intg/structure/extensions/mentalHealthDiagnoses")?.valueString || "[]");
    const diagnosisLabels = diagnosisArray.map((diagnosis: any) => diagnosis.label).join(", ");

    const labelParts = [birthDate, diagnosisLabels].filter(Boolean);
    const descriptionString = labelParts.join(' , ');

    return {
      label: patientName,
      value: patient.id,
      description: descriptionString,
      id: patient.id,
    };
  }

  const parseDateString = (dateString: string): Date => {
    const [year, month, day] = dateString.split('-');
    return new Date(Number(year), Number(month) - 1, Number(day));
  };

  const parseDate = (dateString: string): string => {
    const [year, month, day] = dateString.split('-');
    return `${month}-${day}-${year}`;
  };

  const steps = [
    {
      label: 'Patient Details',
      form: (
        <Form onSubmit={(formValues) => handleStepSubmit(formValues)}>
          <Center display="block">
            <Autocomplete
              className='nameAutocomplete'
              label="Name"
              placeholder="Your name"
              required
              name='patientName'
              value={formData.patientDetails?.patientName || zoomPatientName}
              itemComponent={SelectItem}
              data={patients}
              onChange={(value) => {
                const specialCharPattern = /^[a-zA-Z0-9- ]*$/;
                if (specialCharPattern.test(value)) {
                  handleOnChange(value);
                }
              }}
              onKeyUp={(value) => {
                const specialCharPattern = /^[a-zA-Z0-9- ]*$/;
                if (specialCharPattern.test(value.key)) {
                  handleKeyDown(value)
                }
              }}
              mb={20}
              filter={(value, item) =>
                item?.label?.toLowerCase().includes(value?.toLowerCase()) ||
                item?.description?.toLowerCase().includes(value?.toLowerCase())
              }
              error={validationErrors['patientName']}
            />
            <DateInput
              className='patientDateInput'
              onChange={handleDateChange}
              maw={400}
              maxDate={new Date()}
              value={formData.patientDetails?.birthDate ? parseDateString(formData.patientDetails.birthDate) : null}
              style={{ marginBottom: '20px' }}
              label="Date of Birth"
              placeholder="MM-DD-YYYY"
              valueFormat="MM-DD-YYYY"
            />

            {dataLoaded && (
              <>
                <MultiSelect
                  multiple
                  mb="md"
                  data={diagnosesList}
                  itemComponent={ItemComponent}
                  label="Mental Health Diagnoses"
                  placeholder="Search and select options"
                  defaultValue={formData.patientDetails?.mentalHealthDiagnoses || []}
                  searchable
                  searchValue={searchQuery}
                  onSearchChange={(value) => {
                    const specialCharPattern = /^[a-zA-Z0-9- .]*$/;
                    if (specialCharPattern.test(value)) {
                      handleSearchChange(value)
                    }
                  }}
                  onChange={handleSelectChange}
                  nothingFound={notFoundMental ? 'No mental health diagnoses found' : ''}
                />

                <MultiSelect
                  multiple
                  data={otherDiagnosesList}
                  itemComponent={ItemComponent}
                  label="Other Diagnoses"
                  placeholder="Search and select options"
                  defaultValue={formData.patientDetails?.otherDiagnoses || []}
                  searchable
                  searchValue={otherSearchQuery}
                  onSearchChange={(value) => {
                    const specialCharPattern = /^[a-zA-Z0-9- ]*$/;
                    if (specialCharPattern.test(value)) {
                      handleOtherSearchChange(value)
                    }
                  }}
                  onChange={handleOtherSelectChange}
                  style={{ marginBottom: '20px' }}
                  nothingFound={notfound ? 'No diagnoses found' : ''}
                  icon={loaderICD && (<Loader size={20} />)}
                  dropdownPosition="bottom"
                />
              </>
            )}
          </Center>
        </Form>
      ),
      required: true,
    },
  ];



  const extractAndSetPatientDetails = (response: any) => {
    const patientDetails = {
      patientName: response?.name?.[0].given?.[0] || zoomPatientName || "",
      birthDate: response?.birthDate || "",
      mentalHealthDiagnoses: extractLabels(response?.extension, "/intg/structure/extensions/mentalHealthDiagnoses"),
      otherDiagnoses: extractLabels(response?.extension, "/intg/structure/extensions/otherDiagnoses"),
      existingPatient: 'existing-patient',
      patientId: response?.id
    };

    setSelectedOtherOptions([...JSON.parse(response.extension.find((ext: any) => ext.url === "/intg/structure/extensions/otherDiagnoses")?.valueString || "[]")]);

    setSelectedOptions([...JSON.parse(response?.extension.find((ext: any) => ext.url === "/intg/structure/extensions/mentalHealthDiagnoses")?.valueString || "[]")]);

    setOtherDiagnosesList([...JSON.parse(response.extension.find((ext: any) => ext.url === "/intg/structure/extensions/otherDiagnoses")?.valueString || "[]")]);

    setDiagnosesList([...JSON.parse(response?.extension.find((ext: any) => ext.url === "/intg/structure/extensions/mentalHealthDiagnoses")?.valueString || "[]")]);

    setSelectedRace((prevFormData: any) => {
      return {
        ...prevFormData,
        label: response?.extension.find((ext: any) => ext.url === "http://hl7.org/fhir/StructureDefinition/us-core-race")?.valueCodeableConcept?.coding?.[0]?.display || "",
        value: response?.extension.find((ext: any) => ext.url === "http://hl7.org/fhir/StructureDefinition/us-core-race")?.valueCodeableConcept?.coding?.[0]?.code || "",
      }
    })


    // Constructing the final object
    const result = {
      patientDetails: patientDetails,
    };
    setFormData(result);
    setTimeout(() => {
      setDataLoaded(true);
    }, 100);
  }

  const extractLabels = (extensions: any[], url: string) => {
    const extension = extensions.find(ext => ext.url === url);
    const valueString = extension ? JSON.parse(extension.valueString) : [];
    return valueString.map((diagnosis: { value: any; }) => diagnosis.value).filter((value: any) => !!value);
  };

  const handleBackClick = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <Modal
      size="55rem"
      opened={isOpen}
      onClose={onClose}
      className="context-modal"
      withCloseButton={false}
      closeOnClickOutside={false}
    >
      <Center style={{ textAlign: 'center' }}>
        <Title fs="24px" style={{ lineHeight: '40px' }} fw={700} mb={20}>
          {steps[currentStep]?.label}
        </Title>
      </Center>
      <Group sx={{ justifyContent: 'end' }}>
        {!steps[currentStep].required && (
          <Button className="btn-danger" px={'lg'} style={{ borderRadius: '25px' }} onClick={handleSkip}>
            Skip
          </Button>
        )}

        <Button
          className="btn-success"
          id="save-next-button"
          px={'lg'}
          type="submit"
          form="stepper-form"
          onClick={() => handleStepSubmit(formData)}
          disabled={disablebutton}
        >
          {checkLoader ? (
            <>
              Please wait saving patient...
              <Loader size={20} ml={10} color="green" />
            </>
          ) : (
            <Tooltip
              label="Click to save the patient, notes will be generated automatically."
              withArrow
              position="left"
              sx={{ fontSize: '12px', marginLeft: '-20px', fontWeight: 'normal', maxWidth: '200px', whiteSpace: 'normal', overflowWrap: 'break-word', textAlign: 'left' }}
            >
              <span>Save & Next</span>
            </Tooltip>
          )}
        </Button>
      </Group>
      {isPatientNew === 'new-patient' && (steps[currentStep].form)}

      <Group position="right" mt="lg" style={{ justifyContent: 'space-between' }}>
        {/* {steps[currentStep].required && (
          <Button className="btn-danger" px={'lg'} style={{ borderRadius: '25px' }} onClick={handleCancel} >
            Cancel
          </Button>
        )} */}
        {currentStep > 0 && (
          <Button
            className="btn-danger"
            px={'lg'}
            style={{ borderRadius: '25px' }}
            onClick={handleBackClick}
          >
            Back
          </Button>
        )}

      </Group>

      <ConfirmBox
        isOpen={showConfirmBox}
        onClose={() => setShowConfirmBox(false)}
        onConfirm={handleConfirmCancel}
        patient={''}
        confirm="Delete Session"
        cancel="Cancel"
        message=" Are you sure you want to delete this session?"
        additionalText="You won't be able to recover this session."
      />

    </Modal>
  );
};

export default PatientStepper;
